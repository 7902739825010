import * as yup from "yup";

export const schemaevents = yup.object().shape({
    title:yup.string(),
    time: yup.string(),
    date: yup.string(),
    address: yup.string(),
    zipcode: yup.string(),
    county: yup.string(),
    state: yup.string(),
    broadcast: yup.string(),
    comments: yup.string()

})
export const schemaprofile = yup.object().shape({
    bio1:yup.string(),
    bio2: yup.string(),
    bio3: yup.string(),
    bio4: yup.string(),
    title: yup.string()
})