import React from "react";
import "../button_styles/button-styles.scss";

export default function FileUploadButtons({
  onHandlerUploadFile,
  disableUploadButton,
  onHandlerResetButton,
}) {
  return (
    <>
      <button
        onClick={onHandlerUploadFile}
        disabled={disableUploadButton}
        className="button-small-style"
      >
        {disableUploadButton ? " " : "Upload"}
      </button>

      <button className="button-small-style" onClick={onHandlerResetButton}>
        {"Reset"}
      </button>
    </>
  );
}
