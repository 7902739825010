import React from "react";
import "./form.scss";
/*********************************
 * InputTime
 * usable component for FormEvents.js
 *********************************/
export default function InputTime({ value, change, noreq }) {
  return (
    <input
      className="input-time-wrapper"
      type={"time"}
      name={"time"}
      value={value}
      onChange={change}
      required={!noreq ? true : false}
    />
  );
}
