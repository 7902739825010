import React from "react";
import "./form.scss";
/*********************************
 * InputForm
 * usable component for FormEvents.js
 *********************************/
export default function InputText({
  value,
  change,
  holder,
  small,
  mandatory,
  name,
}) {
  return (
    <input
      className="input-text-wrapper"
      type={"text"}
      name={name}
      value={value}
      onChange={change}
      placeholder={holder}
      style={small ? { width: "7em" } : null}
      // autoComplete={name}
      required={mandatory}
    />
  );
}
