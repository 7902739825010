import React, { useState } from "react";
import FirebaseModule from "../../../firebase/FirebaseModule";
import { Enums } from "../../../enum/enums";
import "../../button_styles/button-styles.scss";

/************************************
 * EventCancel
 *
 ***********************************/
export default function EventCancel({ id, event }) {
  const firebaseModule = FirebaseModule();
  const events = Enums.EVENTS;

  function onHandlerCancel() {
    let eventStatus = !event ? true : false;
    const ref = firebaseModule.updateDocToFirebase(events, id, {
      eventcanceled: eventStatus,
    });
    ref
      .then((res) => {
        alert("Event has been updated");
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  return (
    <button className={"button-small-style"} onClick={() => onHandlerCancel()}>
      {event ? <span style={{ color: "red" }}>canceled</span> : "cancel"}
    </button>
  );
}
