import React, { useId } from "react";

export default function FileInput({ change, fileInputRef }) {
  // useId is used to avoid conflicts between multiple instances
  // of one component
  const filelabelRef = useId();
  return (
    <div>
      <label htmlFor={filelabelRef.toString()}>Select File</label>
      <input
        id={filelabelRef.toString()}
        ref={fileInputRef}
        type={"file"}
        accept={("image/png", "image/jpeg", "png", "jpg", "jpeg")}
        onChange={change}
      />
    </div>
  );
}
