import React from "react";
import "./form.scss";
/*********************************
 * InputDate
 * usable component for FormEvents.js
 *********************************/
export default function InputDate({ value, change, noreq }) {
  return (
    <input
      className="input-time-wrapper"
      type={"date"}
      value={value}
      onChange={change}
      required={!noreq ? true : false}
    />
  );
}
