import React, { useCallback, useEffect, useState } from "react";
import FirebaseModule from "../../firebase/FirebaseModule";
import { Enums } from "../../enum/enums";
import { googleSignIn, auth } from "../../firebase/firebase";
import { useDispatch } from "react-redux";
import { addUserCurrentState, addUserData } from "../../redux/userSlice";
import LocationHerf from "../../location-herf/LocationHerf";
import { useNavigate } from "react-router-dom";
import "../button_styles/button-styles.scss";
import "./login.scss";

/***********************************
 * Login Component
 ***********************************/
export default function Login() {
  const firebaseModule = FirebaseModule();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [buttonState, setbuttonState] = useState(false);
  const admin_path = Enums.ADMIN;
  const id_key = Enums.ID;
  const acces_yes = Enums.ACCESS_YES; // default admin access to admin yes
  const access = Enums.ACCESS;
  const [runsOnce, setRunsOnce] = useState(false);

  const onHandlerAuthStateChange = useCallback(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) return;
      let user_data = {
        id: user.uid,
        access: acces_yes,
        fullName: user.displayName,
        email: user.email,
        photo: user.photoURL,
        verification: user.emailVerified,
      };
      const checkUserStatus = firebaseModule.checkDataInFirebase(
        admin_path,
        user_data.id
      );
      checkUserStatus
        .then((snapShot) => {
          if (!snapShot.exists()) {
            firebaseModule.addDataInFirebase(
              admin_path,
              user_data.id,
              user_data
            );
            dispatch(addUserCurrentState({ allow: true }));
            dispatch(addUserData(user_data));
            navigate("/main");
          } else if (snapShot.exists()) {
            const refAuthVerification =
              firebaseModule.getDocFromFirebaseCompound(
                admin_path,
                id_key,
                user_data.id,
                access,
                acces_yes
              );
            refAuthVerification.then((docsQuery) => {
              docsQuery.forEach((queryDocs) => {
                dispatch(addUserCurrentState({ allow: true }));
                dispatch(addUserData(queryDocs.data()));
                navigate("/main");
              });
            });
          }
        })
        .catch((error) => {
          console.error(error.message);
          setbuttonState(true);
        });
    });
  }, [
    acces_yes,
    access,
    admin_path,
    firebaseModule,
    id_key,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && runsOnce) {
      onHandlerAuthStateChange();
      setRunsOnce(false);
    }

    return () => {
      isMounted = false;
    };
  }, [runsOnce, onHandlerAuthStateChange]);

  function onHandlerSignIn() {
    setRunsOnce(true);
    googleSignIn();
    localStorage.clear();
  }

  return (
    <div className={"login-container"}>
      <h4>Sign-in with your google account</h4>
      <button
        className={"button-medium-style"}
        onClick={() => onHandlerSignIn()}
      >
        {buttonState ? "access denied" : "admin leap"}
      </button>
    </div>
  );
}
