import React, { useState, useEffect } from "react";
import FirebaseModule from "../../../firebase/FirebaseModule";
import FormUpdate from "../../forms/FormUpdate";
import Card from "../../card/Card";
import SearchEngine from "../../../searchEngine/SearchEngine";
import { Enums } from "../../../enum/enums";
import "../../button_styles/button-styles.scss";
import "../main.scss";

/*****************************
 * Update
 * exported to Main.js
 ****************************/
export default function Update() {
  const firebaseModule = FirebaseModule();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [county, setCounty] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [link, setLink] = useState("");
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [ok, setOk] = useState(false);
  const [runsOnce, setRunsOnce] = useState(true);
  const events = Enums.EVENTS;

  useEffect(() => {
    let isMounted = true;
    if (isMounted && runsOnce) {
      const ref = firebaseModule.getAllDocsFromFirebase(events);
      ref
        .then((res) => {
          const d = res.docs.map((items) => items.data());
          setData(d);
          setRunsOnce(false);
          setOk(true);
        })
        .catch((error) => {
          console.error(error.message);
          setRunsOnce(false);
        });
    }

    return () => {
      isMounted = false;
    };
  }, [firebaseModule, runsOnce, events]);

  function onHadlerSelect(
    title_,
    id_,
    addr_,
    state_,
    county_,
    zip_,
    date_,
    time_,
    link_
  ) {
    setTitle(title_);
    setId(id_);
    setAddress(addr_);
    setState(state_);
    setCounty(county_);
    setZipcode(zip_);
    setDate(date_);
    setTime(time_);
    setLink(link_);
  }

  let filteredData = data.filter((item) =>
    item.title.toLowerCase().trim().includes(search.toLowerCase().trim())
  );
  return (
    <div className="update-container">
      <div>
        <FormUpdate
          title1={title}
          id1={id}
          currentdate1={date}
          time1={time}
          address1={address}
          state1={state}
          zip1={zipcode}
          county1={county}
          link1={link}
        />
      </div>
      <div className="update-search-container">
        <SearchEngine
          value={search}
          change={(e) => setSearch(e.target.value)}
          placeholderName={"by title event"}
        />
      </div>

      <div className="update-cards-container">
        {ok
          ? filteredData.map((item) => {
              return (
                <Card
                  key={item.id}
                  c1={item.url}
                  c2={item.title}
                  c3={item.address}
                  c4={item.state}
                  c5={item.county}
                  c6={item.zipcode}
                  c7={item.link}
                  c8={item.yearmonth}
                  c9={item.day}
                  c10={item.time}
                >
                  <button
                    className="button-small-style"
                    onClick={() =>
                      onHadlerSelect(
                        item.title,
                        item.id,
                        item.address,
                        item.state,
                        item.county,
                        item.zipcode,
                        item.date,
                        item.time,
                        item.link
                      )
                    }
                  >
                    Update
                  </button>
                </Card>
              );
            })
          : null}
      </div>
    </div>
  );
}
