import React from "react";

import "./card.scss";

/*************************
 * Card
 ************************/
export default function Card({
  c1,
  c2,
  c3,
  c4,
  c5,
  c6,
  c7,
  c8,
  c9,
  c10,
  children,
}) {
  return (
    <div className="card-container">
      <img src={c1} alt="event_pic" />
      <div className="card-wrapper">
        <h4>
          <span>{c2} </span>
        </h4>
        <h4>{c3}</h4>
        <div style={{ display: "flex" }}>
          <h4>{c4}</h4>
          <h4>{c5}</h4>
          <h4>{c6}</h4>
        </div>
        <h4>{c7}</h4>
        <h4>{`Date:  ${c8}-${c9} `}</h4>
        <h4>{`Time: ${c10}`}</h4>

        {children}
      </div>
    </div>
  );
}
