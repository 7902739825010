import React, { useState, createRef, useId } from "react";
import FirebaseModule from "../../firebase/FirebaseModule";
import InputText from "./InputText";
import InputDate from "./InputDate";
import InputTime from "./InputTime";
import InputUrl from "./InputUrl";
import InputPaste from "./InputPaste";
import { Enums } from "../../enum/enums";
import "../button_styles/button-styles.scss";
import "./form.scss";

/**************************************
 * FormToDatabase
 * exported to Events.js
 **************************************/
export default function FormEvents({ path }) {
  const labelId = useId();
  const [title, setTitle] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [county, setCounty] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [broadcast, setBroacast] = useState("");
  const [comments, setComments] = useState("");
  const [linktitle, setLinktitle] = useState("");
  const [link, setLink] = useState("");
  const firebaseModule = FirebaseModule();
  const url_local_storage = Enums.URL_LOCAL;
  const [url, setUrl] = useState("");
  const pattern = "https";

  function onHandlerSubmitEvent(e) {
    e.preventDefault();
    // converts the date into a stamptime format

    const broadcastdate = new Date(broadcast);
    broadcastdate.setDate(broadcastdate.getDate() + 1);
    const currentdate = new Date(date);
    currentdate.setDate(currentdate.getDate() + 1);
    let d = {
      url: url,
      title: title,
      time: time,
      currentdate: currentdate,
      yearmonth: date.substring(0, 7),
      monthday: date.substring(5),
      month: date.substring(5, 7),
      day: date.substring(8),
      address: address,
      county: county,
      state: state,
      currentbroadcast: broadcast,
      zipcode: zipcode,
      broadcasted: broadcastdate,
      eventcanceled: false,
      comments: comments,
      linktitle: linktitle,
      link: link,
    };

    if (url.includes(pattern)) {
      const ref = firebaseModule.addDocumentWithFirebaseID(path, d);
      ref
        .then((snapShot) => {
          alert("Event has been added");
        })
        .catch((error) => {
          console.log(error.message);
          alert("Failed to add event");
        });
    } else {
      alert("Url is missing");
    }
  }

  //
  function onHandlerGetUrl() {
    setUrl(localStorage.getItem(url_local_storage));
  }

  return (
    <div className={"formtodatabase-container"}>
      <form
        className={"formtodatabase-form-wrapper"}
        onSubmit={onHandlerSubmitEvent}
      >
        <InputText
          value={title}
          name={"name"}
          change={(e) => setTitle(e.target.value)}
          holder={"event title"}
          mandatory
        />
        <InputText
          value={comments}
          name={"search"}
          change={(e) => setComments(e.target.value)}
          holder={"instructions"}
        />
        <div className="form-layout">
          <InputPaste type={"url"} value={url ? url : "Paste Url"} />
          <button className="button-small-style" onClick={onHandlerGetUrl} type="button">
            {"PASTE URL"}
          </button>
        </div>

        <div className="form-layout">
          <label htmlFor={labelId + "-date"}>
            <span>Date & Time: </span>
            <InputDate value={date} change={(e) => setDate(e.target.value)} />
            <InputTime value={time} change={(e) => setTime(e.target.value)} />
          </label>
        </div>
        <label htmlFor={labelId + "-live"}>
          Broadcast on the web:
          <InputDate
            value={broadcast}
            change={(e) => setBroacast(e.target.value)}
          />
        </label>
        <InputText
          value={address}
          change={(e) => setAddress(e.target.value)}
          holder={"Address"}
          name={"address"}
        />
        <div className="form-layout">
          <InputText
            value={county}
            change={(e) => setCounty(e.target.value)}
            small={true}
            holder={"county"}
            name={"name"}
          />
          <InputText
            value={state}
            name={"name"}
            change={(e) => setState(e.target.value)}
            small={true}
            holder={"state"}
          />
           <InputText
            value={zipcode}
            change={(e) => setZipcode(e.target.value)}
            small={true}
            name={"name"}
            holder={"zipcode"}
          />
          </div>
          <div className="form-layout">
         
          <InputText
            value={linktitle}
            name={"search"}
            change={(e) => setLinktitle(e.target.value)}
            holder={"Url title"}
            small
          />
          <InputUrl
            value={link}
            change={(e) => setLink(e.target.value)}
            holder={"https://example.com/"}
            small
          />
        </div>
        <input className="input-button-wrapper" type="submit" value={"Send"} />
      </form>
    </div>
  );
}
