import React from "react";
import StorageModule from "../../firebase/StorageModule";
import "../button_styles/button-styles.scss";

/******************************
 * DeleteFile "deletes from storage database"
 *****************************/
export default function StorageDeleteFile({ path, id}) {
  const storageModule = StorageModule();
 

  function onHandlerDelete() {
    const confirm = window.confirm("Sure wants to delete the file");

    if (confirm) {
      const pathRef = `${path}/${id}`;
      const ref = storageModule.deleteFileFromStorage(pathRef);
      ref
        .then((snapShot) => {
          alert("File has been deleted");
        })
        .catch((error) => {
          console.log(error.message);
          alert("File was not deleted, try again!!");
        });
    }
  }
  return (
    <button className={"button-small-style"} onClick={() => onHandlerDelete()}>
      Delete
    </button>
  );
}
