import axios from "axios";

// send request to backend
/************************************
 * exported to NofiticationSystem.js
 **********************************/
export async function notificationAsistant(title) {
    // console.log('notificationAsistant');
  const callUrl =
    "https://us-central1-lawrencebusiness-aa8ad.cloudfunctions.net/postData";

  const postNotification = {
    id: "New Event",
    title: title,
  };

  await axios
    .post(callUrl, postNotification)
    .then((res) => {
      console.log("Response: ", res.data);
    })
    .catch((error) => {
      console.error("Error: ", error.message);
    });
}

//delete the post to keep the data clean - no need to keep the data
/************************************
 * exported to NofiticationSystem.js
 **********************************/
export async function notificationDelete() {
  const deleteUrl =
    "https://lawrencebusiness-aa8ad-default-rtdb.firebaseio.com/post.json";
  await axios
    .delete(deleteUrl)
    .then((res) => {
      console.log("Response: ", res.data);
    })
    .catch((error) => {
      console.error("Error: ", error.message);
    });
}