import React, { useState, useEffect } from "react";
import FirebaseModule from "../../../firebase/FirebaseModule";
import { Enums } from "../../../enum/enums";
import DeleteFile from "../../../firebase/DeleteFile";
import { uniqueId } from "../../../uuid/uuid";
import "./messages.scss";

/*******************************
 * Messages
 ******************************/
export default function Messages() {
  const [runsOnce, setRunsOnce] = useState(true);
  const [data, setData] = useState([]);
  const firebaseModule = FirebaseModule();
  const messages = Enums.MESSAGES;

  useEffect(() => {
    let mounted = true;
    if (mounted && runsOnce) {
      const ref = firebaseModule.getAllDocsFromFirebase(messages);
      ref
        .then((res) => {
          const response = res.docs.map((item) => item.data());
          setData(response);
          setRunsOnce(false);
        })
        .catch((error) => {
          console.log(error.messages);
          setRunsOnce(false);
        });
    }

    return () => {
      mounted = false;
    };
  }, [runsOnce, firebaseModule, messages]);

  return (
    <div className={"messages-container"}>
      <div className={"messages-new"}>
        {data.map((item) => {
          return (
            <div className={"messages-single-item-wrapper"} key={uniqueId()}>
              <h4>{item.fullName}</h4>
              <h5>{item.email}</h5>
              <h5>{item.textMessage}</h5>
              <div>
                {" "}
                <DeleteFile path={messages} id={item.id} message={"message"} />{" "}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
