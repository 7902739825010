import React, { useState } from "react";
import {
  notificationAsistant,
  notificationDelete,
} from "../../modules/notificationsEvents";
import "../button_styles/button-styles.scss";
import "./notification-system.scss";

/******************************************
 * Notification System
 * exported to events/Events.js
 ******************************************/
export default function NotificationSystem() {
  const [inputMessage, setInputMessage] = useState("");

  function onHandlerNotification(e) {
    e.preventDefault();
    notificationAsistant(inputMessage);
    notificationDelete();
    setInputMessage("");
  }
  return (
    <div>
      <form
        className={"notification-system-container"}
        onSubmit={onHandlerNotification}
      >
        <input
          className={"notification-message"}
          type={"text"}
          name="name"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder={"Enter message"}
          maxLength={40}
          minLength={5}
          required
        />
        <input
          className={"button-medium-style"}
          type="submit"
          value={"send notification"}
        />
      </form>
    </div>
  );
}
