import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    permission: false,
    profileTitle: "",
    profileId: null,
    userData: [],
  },
  reducers: {
    addUserCurrentState: (state, action) => {
      state.permission = action.payload.allow;
    },
    addUserData: (state, action) => {
      // replace the old array with the new array
      return { ...state, userData: action.payload };
    },
    addProfileTitle: (state, action) => {
      state.profileTitle = action.payload.title;
    },
    addProfileId: (state, action) => {
      state.profileId = action.payload.id;
    },
  },
});
export const {
  addUserCurrentState,
  addUserData,
  addProfileId,
  addProfileTitle,
} = userSlice.actions;

export default userSlice.reducer;
