import React, { useState } from "react";
import StorageModule from "../../../firebase/StorageModule";
import StorageManagement from "../../storageManagement/StorageManagement";
import { downloadUrlFromStorage } from "../../../firebase/firebase";
import FormEvents from "../../forms/FormEvents";
import { Enums } from "../../../enum/enums";
import StorageGetAllFiles from "../../storageManagement/StorageGetAllFiles";
import EventsDownload from "./EventsDownload";
import NotificationSystem from "../../notificationSystem/NotificationSystem";
import "../../button_styles/button-styles.scss";
import "./events.scss";

/*******************************
 * Events
 ******************************/
export default function Events() {
  const events = Enums.EVENTS;
  const storageModule = StorageModule();
  const [buttonState, setButtonState] = useState(0);
  const [data, setData] = useState([]);
  const date = new Date();
  const currentDay = date.toISOString().substring(0, 10);

  // prevents the updater function from replicating data
  // twice if use in useEffect
  function onHandlerGetFiles() {
    setData([]);
    setButtonState(2);
    const ref = storageModule.downloadStorageFiles(events);
    ref.then((res) => {
      res.items.forEach((response) => {
        downloadUrlFromStorage(response).then((url) => {
          setData((prev) => {
            return [...prev, { url: url, id: response.name }];
          });
        });
      });
    });
  }

  return (
    <div className={"events-container"}>
      <div className={"events-subcontainer-one"}>
        <StorageManagement path={events} />
        <FormEvents path={events} />
       
      </div>

      <div className={"events-subcontainer-two"}>
      <NotificationSystem />
        <div className={"events-buttons-wrapper"}>
          <button
            className={
              buttonState === 1
                ? "button-medium-highlighted"
                : "button-medium-style"
            }
            onClick={() => setButtonState(1)}
          >
            Download events
          </button>
          <button
            className={
              buttonState === 2
                ? "button-medium-highlighted"
                : "button-medium-style"
            }
            onClick={() => onHandlerGetFiles()}
          >
            Download photos
          </button>
        </div>
        
        {buttonState === 1 ? <EventsDownload path={events} /> : null}
        {buttonState === 2 ? (
          <StorageGetAllFiles data={data} path={events} />
        ) : null}
      </div>
    </div>
  );
}
