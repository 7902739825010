import React from "react";
import Login from "./components/login/Login";
import Main from "./components/maincomponents/Main";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "./RequireAuth";
/***********************************
 * App Component
 ***********************************/
function App() {


  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/main"
          element={
            <RequireAuth>
              <Main />
            </RequireAuth>
          }
        />
      </Routes>
    </>
  );
}

export default App;
