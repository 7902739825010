import React from "react";
import FirebaseModule from "../../firebase/FirebaseModule";
import "../button_styles/button-styles.scss";

/**********************************
 * FormDeleteButton
 * exported to FormProfile.js
 *********************************/
export default function FormDeleteButton({ path, keyid }) {
  const firebaseModule = FirebaseModule();

  function onHandlerDelete() {
    const confirm = window.confirm("To procced with this task, press Ok");
    if (confirm) {
      const ref = firebaseModule.deleteDocFromFirebase(path, keyid);
      ref
        .then((res) => {
          alert("Request has been deleted");
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }

  return (
    <>
      <button
        className={"button-small-style"}
        onClick={() => onHandlerDelete()}
      >
        Delete
      </button>
    </>
  );
}
