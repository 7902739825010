import React, { useState, useEffect } from "react";
import { auth } from "../../firebase/firebase";
import Events from "./events/Events";
import { addUserCurrentState, addUserData } from "../../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Profile from "./profile/Profile";
import Messages from "./messages/Messages";
import Update from "./update/Update";
import { Enums } from "../../enum/enums";
import LocationHerf from "../../location-herf/LocationHerf";
import "../button_styles/button-styles.scss";
import "./main.scss";

/***********************************
 * Main Component
 * exported to App.js
 ***********************************/
export default function Main() {
  const [comp, setComp] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const window_location = LocationHerf();
  const profile = Enums.PROFILLE;
  const events = Enums.EVENTS;
  const messages = Enums.MESSAGES;
  const update = Enums.UPDATE;
  const home = Enums.HOME;

  function onHandlerSelectComp(str) {
    setComp(str);
  }
  function onHandlerSignOut() {
    const ref = auth.signOut();
    ref
      .then(() => {
        dispatch(addUserCurrentState({ allow: false }));
        dispatch(addUserData([]));
        window_location.onGetLocation();
        navigate(home);
        localStorage.clear();
      })
      .catch((error) => {
        console.log(error.message);
        alert("An error has occurred signing you out");
      });
  }
  return (
    <div className={"main-container"}>
      <div className={"main-menu-wrapper"}>
        <button
          className={
            comp === profile ? "button-small-highlighted" : "button-small-style"
          }
          onClick={() => onHandlerSelectComp(profile)}
        >
          Profile
        </button>
        <button
          className={
            comp === messages
              ? "button-small-highlighted"
              : "button-small-style"
          }
          onClick={() => onHandlerSelectComp(messages)}
        >
          Messages
        </button>
        <button
          className={
            comp === events ? "button-small-highlighted" : "button-small-style"
          }
          onClick={() => onHandlerSelectComp(events)}
        >
          Events
        </button>
        <button
          className={
            comp === update ? "button-small-highlighted" : "button-small-style"
          }
          onClick={() => onHandlerSelectComp(update)}
        >
          Update
        </button>
        <button
          className={"button-small-style"}
          onClick={() => onHandlerSignOut()}
        >
          Sign Out
        </button>
      </div>
      <div className={"main-content-wrapper"}>
        {comp === profile ? <Profile /> : null}
        {comp === events ? <Events /> : null}
        {comp === messages ? <Messages /> : null}
        {comp === update ? <Update /> : null}
      </div>
    </div>
  );
}
