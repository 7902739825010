import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

/********************************
 * RequireAuth
 * exported to App.js
 *******************************/
export default function RequireAuth({ children }) {
  const currentuser = useSelector((state) => state.user.permission);
  let location = useLocation();

  if (!currentuser) {
    // provides a redirection to the login page, providing sucurity and
    // good UX
    return <Navigate to={"/"} state={{ from: location }} replace />;
  }
  return children;
}
