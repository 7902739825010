import React, { useState, useEffect, useMemo } from "react";
import FirebaseModule from "../../../firebase/FirebaseModule";
import DeleteFile from "../../../firebase/DeleteFile";
import { uniqueId } from "../../../uuid/uuid";
import SearchEngine from "../../../searchEngine/SearchEngine";
import SelectFile from "../../fileInput/SelectFile";
import EventCancel from "./EventCancel";
import "../../button_styles/button-styles.scss";
/**************************************
 * EventsDownload
 *************************************/
export default function EventsDownload({ path }) {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const firebaseModule = FirebaseModule();
  const [runsOnce, setRunsOnce] = useState(true);
  let date = new Date();

  // this operation convernts the millliseconds to seconds
  // useMemo is applied to avoid unnecesary rendering
  const todaysdate = useMemo(() => Math.floor(Date.now() / 1000), []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && runsOnce) {
      const ref = firebaseModule.getAllDocsFromFirebase(path);
      ref
        .then((res) => {
          const d = res.docs.map((items) => items.data());
          setData(d);
          setRunsOnce(false);
        })
        .catch((error) => {
          console.log(error.message);
          setRunsOnce(false);
        });
    }

    return () => {
      isMounted = false;
    };
  }, [path, data, firebaseModule, runsOnce]);

  function onHandlerChange(e) {
    const n = e.target.value;
    let formatted = n
      .replace(/[^0-9]/g, "") // Remove non-numeric characters
      .replace(/(\d{2})/, "$1-"); // Insert a hyphen after every two digits
    // Remove the last hyphen if it exists and the user is deleting a character
    // if (formatted.lenght > 5) {
    //   formatted = formatted.replace(/-(?=[^-]*$)/, "");
    // }
    setSearch(formatted);
  }

  let filteredData = data.filter((item) =>
    item.monthday.toLowerCase().trim().includes(search.toLowerCase().trim())
  );
  return (
    <div>
      <div className={"events-download-search-wrapper"}>
        <h5>Search by Date month-day</h5>
        <SearchEngine
          value={search}
          change={onHandlerChange}
          placeholderName={"by date month-day"}
          mx
        />
      </div>
      <div className={"events-indicator-container"}>
        <div className={"events-indicator-wrapper"}>
          <div className={"events-indicator-green"} />
          <span>Posted</span>
        </div>
        <div className={"events-indicator-wrapper"}>
          <div className={"events-indicator-yellow"} />
          <span>Not Posted Yet</span>
        </div>
        <div className={"events-indicator-wrapper"}>
          <div className={"events-indicator-red"} />
          <span>Event Past Due</span>
        </div>
      </div>
      <div className={"events-download-container"}>
        {filteredData.map((item) => {
          return (
            <div className={"events-download-single-wrapper"} key={uniqueId()}>
              <div>
                <img
                  style={{ width: "4em", height: "2em" }}
                  src={item.url}
                  alt="events"
                />
              </div>
              <div className={"events-download-info-wrapper"}>
                <div
                  className={
                    item.broadcasted.seconds > todaysdate &&
                    item.currentdate.seconds > todaysdate
                      ? "events-indicator-yellow"
                      : null
                  }
                />
                <div
                  className={
                    item.broadcasted.seconds < todaysdate &&
                    item.currentdate.seconds > todaysdate
                      ? "events-indicator-green"
                      : null
                  }
                />
                <div
                  className={
                    item.currentdate.seconds < todaysdate &&
                    item.currentdate.seconds < todaysdate
                      ? "events-indicator-red"
                      : null
                  }
                />

                <h4>{item.title} </h4>
                <h4>{`Date: ${item.month} / ${item.day} / ${date.getFullYear(
                  item.currentdate
                )}`}</h4>
                <h6>{`Broadcast: ${item.currentbroadcast.substring(
                  5,
                  7
                )} / ${item.currentbroadcast.substring(
                  8
                )} / ${item.currentbroadcast.substring(0, 4)} `}</h6>
                <h6>{`${item.address} ${item.county} ${item.state} ${item.zipcode}`}</h6>
                <h5>{`Time: ${item.time} `}</h5>
                <h5>{`Instructions: ${item.comments}`}</h5>
                <div className={"events-download-button-wrapper"}>
                  <DeleteFile path={path} id={item.id} message={"delete"} />
                  <EventCancel id={item.id} event={item.eventcanceled} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
