import React from "react";
import "./form.scss";
/******************************
 * InputPaste
 ****************************/
export default function InputPaste({ type, value }) {
  return (
    <input
      className="input-url-wrapper"
      type={type}
      name={type}
      value={value}
      disabled
    />
  );
}
