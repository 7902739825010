import React from "react";
import './form.scss'

/*****************************
 *InputUrl
 ****************************/
export default function InputUrl({ value, change, holder, small }) {
  return (
    <input
    className="input-url-wrapper"
      type={"url"}
      value={value}
      name={"url"}
      onChange={change}
      placeholder={holder}
      
    />
  );
}
