import React from "react";
import "./search-engine.scss";


/************************************
 * SearchEngine
 * exported to EventsDownaload.js and Update.js
 ***********************************/
export default function SearchEngine({ value, change, placeholderName, mx }) {
  return (
    <div className={"search-engine-main"}>
      <input
        className={"search-engine-container"}
        value={value}
        type="search"
        maxLength={mx ? 5 : 40}
        onChange={change}
        placeholder={`Search ${placeholderName}`}
      />
      <div className={"search-engine-wrapper"}>
        <div className={"search-engine-deco"} />
      </div>
    </div>
  );
}
