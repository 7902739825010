import React, { useEffect, useState } from "react";
import FirebaseModule from "../../../firebase/FirebaseModule";
import { Enums } from "../../../enum/enums";
import "../../button_styles/button-styles.scss";

/*********************************
 * ProfileActive
 * exported to FormProfile.js
 * user can choose a specific profile for the main website
 ********************************/
export default function BroadcastProfile({ id, profileinuse, data, setData }) {
  const firebaseModule = FirebaseModule();
  const profile = Enums.PROFILLE;
  const [activateProfile, setActivateProfile] = useState([]);
  const [actionTaken, setActionTaken] = useState(false);
  const inuse = true;
  const notinuse = false;

  useEffect(() => {
    let isMounted = true;
    if (activateProfile.length === 0 && isMounted) {
      const r = data.find((item) => item.useprofile === true);
      if (!r) {
        setActivateProfile(...activateProfile, { profilesActived: 0 });
        // profilesActivated variable provides a safety net in case of an undefined result
        // this variable will activate to clean the data pass as a prop from FormProfile.js
      } else {
        setActivateProfile(r);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [activateProfile, data]);

  // this functions checks that only on profile is activated
  function onHandlerActivate() {
    if (!activateProfile.useprofile) {
      onUpdate(inuse);
      if (activateProfile.profilesActived === 0) {
        setData([]); // pass as a prop
      }
    } else if (activateProfile.useprofile) {
      alert("One profile is activated, diactivate the profile in use");
    }
  }

  // buttons functions
  function onHandlerDiactivate() {
    if (!actionTaken) {
      onUpdate(notinuse);
      setActionTaken(true);
    } else {
      alert("Click on (Download Profiles) to refresh actions");
    }
  }
  // helper function
  function onUpdate(value) {
    const ref = firebaseModule.updateDocToFirebase(profile, id, {
      useprofile: value,
    });
    ref
      .then((res) => {
        alert("Profile has been updated");
      })
      .catch((error) => {
        alert("The request failed");
        console.log(error.message);
      });
  }

  return (
    <>
      {profileinuse ? (
        <button
          className={"button-medium-style"}
          onClick={() => onHandlerDiactivate()}
        >
          Diactiave Profile
        </button>
      ) : (
        <button
          className={"button-medium-style"}
          onClick={() => onHandlerActivate()}
        >
          Broadcast Profile
        </button>
      )}
    </>
  );
}
