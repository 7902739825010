import React, { useState, useEffect } from "react";
import { downloadUrlFromStorage } from "../../firebase/firebase";
import StorageModule from "../../firebase/StorageModule";
import StorageDeleteFile from "../fileInput/StorageDeleteFile";
import SelectFile from "../fileInput/SelectFile";
import { uniqueId } from "../../uuid/uuid";
import "../button_styles/button-styles.scss";
import "./storage-management.scss";

/************************************
 * StorageGetAllFiles
 * exported to Events.js
 ***********************************/
export default function StorageGetAllFiles({ data, path }) {

  return (
    
      <div className={"storage-get-all-files-container"}>
        {data ? data.map((item) => {
              return (
                <div
                  className={"storage-get-all-files-single-wrapper"}
                  key={uniqueId()}
                >
                  <img src={item.url} alt={item.id} />

                  <div style={{ display: "flex" }}>
                    <StorageDeleteFile path={path} id={item.id} />
                    <SelectFile url={item.url} />
                  </div>
                </div>
              );
            })
          : null}
      </div>
    
  );
}
