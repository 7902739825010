import React, { useRef, useState, useEffect, useMemo } from "react";
import FileInput from "../fileInput/FileInput";
import FileUploadButtons from "./FileUploadButtons";
import StorageModule from "../../firebase/StorageModule";
import StorageDownloadFile from "./StorageDownloadFile";
import "./storage-management.scss";

/***************************************
 * StorageManagement
 * exported to Events.js
 ***************************************/
export default function StorageManagement({ path }) {
  const fileRef = useRef();
  const storageModule = StorageModule();
  const [fileUploaded, setFileUploaded] = useState(null);
  const [disableUploadButton, setDisableUploadButton] = useState(true);
  const kilobytes_limit = 900;
  const divided = 1024;

  // use to disable the button in FileUploadButtons.js
  useEffect(() => {
    let isMounted = true;
    if (isMounted && fileUploaded) {
      setDisableUploadButton(false);
    }

    return () => {
      isMounted = false;
    };
  }, [fileUploaded]);

  // memorize the result to prevent unnecessary re-renders
  const filezise = useMemo(() => {
    if (fileUploaded) {
      return (fileUploaded.size / divided).toFixed(2);
    }
  }, [fileUploaded]);

  // file must be uploaded with the metadata
  function onHandlerUploadFile() {
    // 900 is based on kilobytes
    if (filezise < kilobytes_limit) {
      const ref = storageModule.uploadFileToStorage(path, fileUploaded);
      ref
        .then((snapShot) => {
          onHandlerResetButton();
          alert("File uploaded");
        })
        .catch((error) => {
          console.log(error.message);
          alert("The file could not be uploaded");
        });
    } else if (filezise > kilobytes_limit) {
      alert("File is greater than 900 KB, reduce the file size");
    }
  }

  function onHandlerResetButton() {
    setFileUploaded(null);
    setDisableUploadButton(true);
    fileRef.current.value = ""; // cleans the input on FileInput
  }

  return (
    <div className={"storage-management-container"}>
      <FileInput
        fileInputRef={fileRef}
        change={(e) => setFileUploaded(e.target.files[0])}
      />
      <div style={{ display: "flex" }}>
        <FileUploadButtons
          disableUploadButton={disableUploadButton}
          onHandlerUploadFile={onHandlerUploadFile}
          onHandlerResetButton={onHandlerResetButton}
        />
        <StorageDownloadFile path={path} />
      </div>
    </div>
  );
}
