import React, { useState } from "react";
import FirebaseModule from "../../firebase/FirebaseModule";
import FormDeleteButton from "./FormDeleteButton";
import BroadcastProfile from "../maincomponents/profile/BroadcastProfile";
import { useDispatch } from "react-redux";
import { addProfileId, addProfileTitle } from "../../redux/userSlice";
import { Enums } from "../../enum/enums";
import "./form.scss";

/**********************************
 * FormProfileInfo
 * exported to FormProfile.js
 *********************************/
export default function FormProfileInfo() {
  const firebaseModule = FirebaseModule();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [bttnStateDownload, setBttnStateDownload] = useState(false);

  const [buttonState, setButtonState] = useState("");
  const profile = Enums.PROFILLE;

  function onDownloadProfile() {
    setData([]);
    setBttnStateDownload(false);
    const ref = firebaseModule.getDocInFirebase(profile, profile, true);
    ref
      .then((res) => {
        res.forEach((docQuey) => {
          setData((prev) => {
            return [...prev, docQuey.data()];
          });
          setBttnStateDownload(true);
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  }
  function onClickedProfile(key, title) {
    setButtonState(key);
    dispatch(addProfileId({ id: key }));
    dispatch(addProfileTitle({ title: title }));
  }
  return (
    <>
      <h4 style={{ textAlign: "center" }}>
        To broadcast a new profile, you must diactivate the current profile
      </h4>
      <div className={"form-profile-button-wrapper"}>
        <button
          className={
            !bttnStateDownload
              ? "button-medium-style"
              : "button-medium-highlighted"
          }
          onClick={() => onDownloadProfile()}
        >
          Download Profiles
        </button>
      </div>

      <div className={"form-profile-display-wrapper"}>
        {data.map((item) => {
          return (
            <div
              className={
                buttonState === item.id
                  ? "form-profile-single-container-red"
                  : "form-profile-single-container"
              }
              key={item.id}
            >
              <h3 style={{ color: "green", margin: "0" }}>
                {item.useprofile ? `Active Profile` : null}
              </h3>
              <h3>{item.title.length > 3 ? item.title : null}</h3>
              <p>{item.bio1.length > 3 ? item.bio1 : null}</p>
              <p>{item.bio2.length > 3 ? item.bio2 : null}</p>
              <p>{item.bio3.length > 3 ? item.bio3 : null}</p>
              <p>{item.bio4.length > 3 ? item.bio4 : null}</p>

              <div className={"form-profile-buttons-container"}>
                <button
                  className={
                    buttonState === item.id
                      ? "button-medium-highlighted-word"
                      : "button-medium-style"
                  }
                  onClick={() => onClickedProfile(item.id, item.title)}
                >
                  {buttonState === item.id
                    ? "Ready to Update"
                    : "Update Profile"}
                </button>
                <BroadcastProfile
                  id={item.id}
                  profileinuse={item.useprofile}
                  data={data}
                  setData={() => setData([])}
                />
                <FormDeleteButton path={profile} keyid={item.id} />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
