import React, { useState } from "react";
import { Enums } from "../../enum/enums";
import "../button_styles/button-styles.scss";

/*************************************
 * SelectFile
 * exported to StorageGetAllFiles.js and EventsDownload.js
 ************************************/
export default function SelectFile({ id, url }) {
  const [buttonState, setButtonState] = useState(false);
  const local_storage_url = Enums.URL_LOCAL;
  const local_storage_id = Enums.ID_LOCAL;

  function onHandlerFileSelected() {
    localStorage.clear();
    setButtonState(false);
    onHandlerToLocalStorage(local_storage_id, id);
    onHandlerToLocalStorage(local_storage_url, url);
    setButtonState(true);
  }

  
  function onHandlerToLocalStorage(key, value) {
    localStorage.setItem(key, value);
  }


  let spanb = <span style={{ color: "red" }}>selected</span>;
  return (
    <button
      className={"button-small-style"}
      onClick={() => onHandlerFileSelected()}
    >
      {buttonState ? spanb : "Select"}
    </button>
  );
}
