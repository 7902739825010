import React, { useState, useEffect } from "react";
import { Enums } from "../../enum/enums";
import StorageModule from "../../firebase/StorageModule";
import { downloadUrlFromStorage } from "../../firebase/firebase";
import "../button_styles/button-styles.scss";

/**********************************
 * StorageDownloadFile
 * exported to StorageManagement.js
 * Enums.LOCAL_STORAGE_FILE_NAME is being assigned in firebase/StorageModule.js
 *********************************/
export default function StorageDownloadFile({ path }) {
  const storageModule = StorageModule();
  const [buttonState, setButtonState] = useState(false);
  const url_local_storage_name = Enums.LOCAL_STORAGE_FILE_NAME;
  const url_local_storage = Enums.URL_LOCAL;

  useEffect(() => {
    let timeId;
    if (buttonState) {
      timeId = setTimeout(() => {
        setButtonState(false);
      }, 8000);
    }

    return () => {
      clearTimeout(timeId);
    };
  }, [buttonState]);

  function onHandlerDownloadFile() {
    const ref = storageModule.downloadStorageFiles(`${path}/`);
    ref
      .then((res) => {
        res.items.forEach((response) => {
          if (response.name === localStorage.getItem(url_local_storage_name)) {
            downloadUrlFromStorage(response).then((url) => {
              localStorage.setItem(url_local_storage, url);
              setButtonState(true);
            });
          }
        });
      })
      .catch((error) => {
        console.log(error.message);
        alert("File could not be downloaded");
      });
  }
  
  return (
    <button
      className={"button-small-style"}
      onClick={() => onHandlerDownloadFile()}
    >
      {!buttonState ? "Copy Url" : "Url Copied"}
    </button>
  );
}
