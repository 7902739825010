import React, { useState, useId, } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaprofile } from "./yupShema";
import FirebaseModule from "../../firebase/FirebaseModule";
import { useSelector, useDispatch } from "react-redux";
import { addProfileId, addProfileTitle } from "../../redux/userSlice";
import "../button_styles/button-styles.scss";
import "./form.scss";

/****************************
 * FormProfile
 ****************************/
export default function FormProfile({ path }) {
  const firebaseModule = FirebaseModule();
  const dispatch = useDispatch();
  const [checkBoxvalue, setCheckBoxvalue] = useState(false);
  const profile_id = useSelector((state) => state.user.profileId);
  const profile_title = useSelector((state) => state.user.profileTitle);
  const labelId = useId(); // to avoid conflicts between multiple instances
  const bio1 = "bio1";
  const bio2 = "bio2";
  const bio3 = "bio3";
  const bio4 = "bio4";
  const title = "title";

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      title: "",
      bio1: "",
      bio2: "",
      bio3: "",
      useprofile: false,
      profile: true,
    },
    resolver: yupResolver(schemaprofile),
  });

  function onHandlerSubmit(data) {
    const { bio1, bio2, bio3, useprofile, profile, title } = data;
    const content = {
      title: title,
      bio1: bio1,
      bio2: bio2,
      bio3: bio3,
      bio4: bio4,
      useprofile: useprofile,
      profile: profile,
    };
    if (checkBoxvalue) {
      onCreateNewProfile(content);
      setCheckBoxvalue(false);
    } else if (profile_id && !checkBoxvalue) {
      onUpdateProfile(content);
      dispatch(addProfileId({ id: null }));
      dispatch(addProfileTitle({ title: "" }));
    } else if (!profile_id && !checkBoxvalue) {
      alert(
        "Click on create new profile or choose the profile you want to update"
      );
    }
  }

  // function is passed on onHandlerSubmit function
  function onUpdateProfile(updateData) {
    const ref = firebaseModule.updateDocToFirebase(
      path,
      profile_id,
      updateData
    );
    ref
      .then((res) => {
        alert("Profile has been updated");
        reset();
      })
      .catch((error) => {
        alert("Profile could not be updated");
        console.log(error.message);
      });
  }
  // function is passed on onHandlerSubmit function
  function onCreateNewProfile(newData) {
    const ref = firebaseModule.addDocumentWithFirebaseID(path, newData);
    ref
      .then((res) => {
        alert("New profile has been created");
        reset();
      })
      .catch((error) => {
        alert("Error occured, check with admin");
        console.log(error.message);
      });
  }

  return (
    <div className={"form-profile-container"}>
      <div>
        <label htmlFor={labelId}>
          Create a new profile
          <input
            type={"checkbox"}
            id={labelId}
            checked={checkBoxvalue}
            onChange={(e) => setCheckBoxvalue(e.target.checked)}
          />
        </label>
      </div>
      <form
        className={"form-profile-wrapper"}
        onSubmit={handleSubmit(onHandlerSubmit)}
      >
        <input
          style={{ width: "13em" }}
          placeholder={"title"}
          type={"text"}
          required
          {...register(title)}
        />
        <p>{`Title: ${profile_title}`}</p>
        <textarea {...register(bio1)} required placeholder={"Paragraph 1"} />
        <textarea {...register(bio2)} placeholder={"Paragraph 2"} />
        <textarea {...register(bio3)} placeholder={"Paragraph 3"} />
        <textarea {...register(bio4)} placeholder={"Paragraph 4"} />
        <input
          style={{ marginBottom: "5em", cursor: "pointer", borderRadius: "0" }}
          type={"submit"}
          value={"send"}
        />
      </form>
    </div>
  );
}
