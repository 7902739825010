import React from "react";
import FirebaseModule from "./FirebaseModule";
import "../components/button_styles/button-styles.scss";

export default function DeleteFile({ path, id, message }) {
  const firebaseModule = FirebaseModule();

  function onHandlerDelete() {
    const ref = firebaseModule.deleteDocFromFirebase(path, id);
    ref
      .then((res) => {
        alert("File Deleted");
      })
      .catch((error) => {
        console.log(error.message);
        alert("File was not deleted");
      });
  }

  return (
    <button className={"button-small-style"} onClick={() => onHandlerDelete()}>
      {message}
    </button>
  );
}
