import { ref, listAll, uploadBytes } from "firebase/storage";
import { storage, deleteFile } from "./firebase";
import { uniqueId } from "../uuid/uuid";
import { Enums } from "../enum/enums";

/***************************************
 * Storage Module (closure (encapsulation))
 * exported to ImageUpload
 ***************************************/

export default function StorageModule() {
  // use to download the video when selected
  const localstoragefilename = Enums.LOCAL_STORAGE_FILE_NAME;
  // metadata is essential for the proper display of the content
  // https://firebase.google.com/docs/storage/web/upload-files
  async function onUploadFileToStorage(storageFolder, fileToUpload) {
    localStorage.clear();
    // fileToUpload contains the necessary metadata for the proper functioning of the file in storage
    if (fileToUpload && storageFolder) {
      // adding a unique identifier (uniqueId) prevents conflicts with files having the same name or ID 
      const id = `${fileToUpload.name + uniqueId()}`;
      const storageRef = ref(storage, `${storageFolder}/${id}`);
      const reff = await uploadBytes(storageRef, fileToUpload).then(() => {
        // the variable "localstoragefilename" is assigned for later comparison in "StorageDownloadFile.js"
        localStorage.setItem(localstoragefilename, id);
      });
      return reff;
    }
  }

  // https://firebase.google.com/docs/storage/web/delete-files
  async function onDeleteFileFromStorage(pathFileToDelete) {
    const storageRef = ref(storage, pathFileToDelete);
    const ref1 = await deleteFile(storageRef);
    return ref1;
  }

  // returns a promise for later use
  async function onDownloadStorageFiles(pathForFile) {
    const storageRef = ref(storage, pathForFile);
    const lists = await listAll(storageRef);
    return lists;
  }

  ////////////////////////////////////////////////////////////

  async function uploadFileToStorage(storageFolder, fileToUpload) {
    return await onUploadFileToStorage(storageFolder, fileToUpload);
  }

  async function deleteFileFromStorage(pathFileToDelete) {
    return await onDeleteFileFromStorage(pathFileToDelete);
  }

  async function downloadStorageFiles(pathForFile) {
    return await onDownloadStorageFiles(pathForFile);
  }

  return {
    uploadFileToStorage,
    deleteFileFromStorage,
    downloadStorageFiles,
  };
}
