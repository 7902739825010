import React from "react";
import FormProfile from "../../forms/FormProfile";
import FormProfileInfo from "../../forms/FormProfileInfo";
import { Enums } from "../../../enum/enums";
/****************************
 * Profile
 ****************************/
export default function Profile() {
  const profile = Enums.PROFILLE;
  return (
    <div>
      <FormProfile path={profile} />
      <FormProfileInfo />

    </div>
  );
}
