export const Enums = Object.freeze({
  USER: "user",
  ID: "id",
  ADMIN: "admin",
  EVENTS: "events",
  MESSAGES: "messages",
  ACCESS_YES: "yes", // value from access property in database
  ACCESS: "access",
  PROFILLE: "profile",
  UPDATE: "update",
  HOME: "/",
  LOCAL_STORAGE_FILE_NAME: "storagefilename", // used for single file selector in StorageDownloadFile.js
  STORAGE_EVENTS: "events",
  URL: "url",
  ID_LOCAL: "idleap23", // use for the local storage
  URL_LOCAL: "urlleap23", // use for the local storage
  ACTIVATE_PROFILE_KEY: "ozHRqbQ21nVEEp75Sr0A",
});
