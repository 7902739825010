import React, { useState } from "react";
import InputTime from "./InputTime";
import InputDate from "./InputDate";
import InputUrl from "./InputUrl";
import InputText from "./InputText";
import { Enums } from "../../enum/enums";
import FirebaseModule from "../../firebase/FirebaseModule";
import "../button_styles/button-styles.scss";
import "./form.scss";

/******************************
 * FormUpdate
 *****************************/
export default function FormUpdate({
  title1,
  currentdate1,
  time1,
  state1,
  zip1,
  address1,
  county1,
  link1,
  id1,
}) {
  const firebaseModule = FirebaseModule();
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [county, setCounty] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [link, setLink] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const events = Enums.EVENTS;

  function onHandlerUpdate(e) {
    e.preventDefault();
    const currentdate = new Date(date);
    currentdate.setDate(currentdate.getDate() + 1);
    if (id1) {
      const ref = firebaseModule.updateDocToFirebase(events, id1, {
        link: link ? link : link1,
        address: address ? address : address1,
        state: state ? state : state1,
        county: county ? county : county1,
        currentdate: date ? currentdate : currentdate1,
        time: time ? time : time1,
        zipcode: zipcode ? zipcode : zip1,
        yearmonth: date ? date.substring(0, 7) : currentdate1.substring(0, 7),
        monthday: date ? date.substring(5) : currentdate1.substring(5),
        month: date ? date.substring(5, 7) : currentdate1.substring(5, 7),
        day: date ? date.substring(8) : currentdate1.substring(8),
      });
      ref
        .then(() => {
          alert("File was updated");
        })
        .catch((error) => {
          console.error(error.message);
          alert("File was NOT updated");
        });
    }
  }

  return (
    <div className="form-update-container">
      <h4>{title1}</h4>
      <form onSubmit={onHandlerUpdate}>
        <InputText
          value={address}
          name={"address"}
          holder={"Address"}
          change={(e) => setAddress(e.target.value)}
        />
        <div>
          <InputText
            value={state}
            holder={"state"}
            name={"name"}
            change={(e) => setState(e.target.value)}
            small
          />
          <InputText
            value={county}
            holder={"county"}
            name={"name"}
            change={(e) => setCounty(e.target.value)}
            small
          />
          <InputText
            value={zipcode}
            holder={"zipcode"}
            name={"name"}
            change={(e) => setZipcode(e.target.value)}
            small
          />
        </div>
        <div className="form-layout">
          <h5>Date & Time</h5>
          <InputDate
            value={date}
            change={(e) => setDate(e.target.value)}
            noreq
          />
          <InputTime
            value={time}
            change={(e) => setTime(e.target.value)}
            noreq
          />
        </div>

        <InputUrl
          value={link}
          change={(e) => setLink(e.target.value)}
          holder={"https://example.com/"}
          small
        />
        <input
          className="input-button-wrapper"
          type="submit"
          value={"update"}
        />
      </form>
    </div>
  );
}
